<template>
  <div>
    <!-- <div>其他</div> -->
    <div class="Hot" v-if="List!=''">
      <van-list v-model="loading" style="display:flex;flex-wrap: wrap;justify-content: space-between;"
            :finished="finished" finished-text="没有更多了" @load="scroll">
      <div class="Hot_List" v-for="(item, index) in List" :key="index" @click="Go_xq(item.id)">
        <div class="Hot_List_top">
          {{ item.statement_title }}
        </div>
        <div class="Hot_List_content">
          <div class="Hot_List_content_top">
            <span>[投诉对象]</span>
            <span>{{ item.business_name }}</span>
          </div>
          <div class="Hot_List_content_top">
            <span>[投诉理由]</span>
            <span>{{ item.complaint_reason }}</span>
          </div>
        </div>
        <div class="Hot_List_bottom">
          <div class="Hot_List_bottom_left">
            <div>
              <img src="@/assets/img/baoguang_icon.png" alt="">
              <span>{{item.pv_count}}</span>
            </div>
            <div>
              <img src="@/assets/img/dianzan_icon.png" alt="">
              <span>{{ item.like_num }}</span>
            </div>
          </div>
          <div class="Hot_List_bottom_right">
            <div v-if="item.status == 2" class="is_Hot">已完成</div>
            <div v-if="item.status == 1" class="is_Hot1">处理中</div>
          </div>
        </div>
        <div class="Hot_bottom">
          <div class="Hot_bottom_left">
            <img :src="item.headimgurl" alt="">
            <span>{{ item.nickname }}</span>
          </div>
          <div class="Hot_bottom_right">
            {{item.create_time.slice(0,10)}}
          </div>
        </div>
      </div>
    </van-list>
    </div>
    <div v-else style="text-align:center;margin-top:20px;">暂无数据</div>
    <!-- <div v-if="load==true" style="text-align:center;">加载中...</div>
    <div v-if="load==false" style="text-align:center;"></div> -->
  </div>
</template>

<script>
import { complaint_index, industry_type } from "@/api/api.js";
import Axios from "axios";
import md5 from 'js-md5';
export default {
   metaInfo(){
     return{
        title: '品牌列表',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      List: [],
      load: true,
      page: 2,
      loading: false,
      finished: false,
    };
  },
  methods: {
    complaint_index() {
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/industry_type",
        params: {
          industry_id: 'other',
          key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
        },
      }).then((res) => {
        //console.log(res.data.data);
        this.List = res.data.data.data;
      });
    },
    //投诉分页
    scroll() {
      var that = this;
    Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/industry_type",
        params: {
          type: 0,
          page: this.page,
          industry_id: 'other',
          key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
        },
      }).then((res) => {
        setTimeout(() => {
          this.page = this.page + 1;
          if (res.data.data.data.length > 0) {
            this.List.push(...res.data.data.data);
            this.loading = false;
          } else {
            this.finished = true;
          }
        }, 500);
      });
    },
    // scroll() {
    //   window.onscroll = async () => {
    //     // 距离底部200px时加载一次
    //     let bottomOfWindow =
    //       document.documentElement.scrollHeight -
    //         document.documentElement.clientHeight -
    //         document.documentElement.scrollTop <=
    //       0.25;
    //     let isLoading = true; //是否有数据可以加载
    //     if (bottomOfWindow && this.load == true) {
    //       this.page = this.page + 1; //每次分页+1
    //       await Axios({
    //         method: "get",
    //         url: "https://tsadmin.qudong.com/api/industry_type",
    //         params: {
    //           type: 0,
    //           page: this.page,
    //           industry_id: 'other',
    //           key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
    //         },
    //       }).then((res) => {
    //         //console.log(res);
    //         if (res.data.data.data.length > 0) {
    //           this.List.push(...res.data.data.data); //追加数据 使用 ...语法
    //           this.load = true;
    //         } else {
    //           this.load = false;
    //         }
    //       });
    //     }
    //   };
    // },
    // 跳转详情页面
    Go_xq(id) {
      //console.log(id);
      this.$router.push({ name: "Details", query: { id: id } });
    },
  },
  mounted() {
    let that = this;
    that.complaint_index();
    // window.addEventListener('scroll', that.scroll, true)     //滚动加载更多
  },
};
</script>

<style scoped>
.Hot {
  margin-top: 20px;
  width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Hot_List {
  width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.Hot_List_top {
  margin-bottom: 15px;
}
.Hot_List_content_top span:nth-child(1) {
  color: #1f72d9;
}
.Hot_List_content_top span:nth-child(2) {
  color: #666666;
}
.Hot_List_bottom {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}
.Hot_List_content_top {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Hot_List_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_List_bottom_left span {
  color: #999999;
  font-size: 14px;
}

.Hot_List_bottom_left div {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.Hot_List_bottom_left div:nth-child(1) img {
  width: 24px;
  height: 14px;
  margin-right: 5px;
}
.Hot_List_bottom_left div:nth-child(2) img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.is_Hot {
  background: #d2e3f7;
  opacity: 0.7;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #1f72d9;
  font-weight: bold;
}
.is_Hot1 {
  background: #ffe9d3;
  opacity: 0.7;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fea962;
  font-weight: bold;
}
.Hot_bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hot_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_bottom_left img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
.Hot_bottom_right {
  font-size: 14px;
  color: #999999;
}
>>> .van-list__finished-text {
  width: 900px;
}
>>> .van-list__loading {
  width: 900px;
}
</style>